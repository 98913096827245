import axios from 'axios'

class CustomerApi {
  async get({ page, limit, search }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get(`counting/customers?page=${page}&limit=${limit}&search=${search}`)
    return response
  }

  async getAll() {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.get('counting/customers/getAll')
    return response
  }

  async add({ customer_name, customer_birthday, customer_image, customer_address, customer_mobile, customer_note }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .post('counting/customers', {
        customer_name,
        customer_birthday,
        customer_image,
        customer_address,
        customer_mobile,
        customer_note,
      })
      .then(Response => Response)
      .catch(error => {
        return error.response
      })
  }

  async edit({
    customer_id,
    customer_name,
    customer_birthday,
    customer_image,
    customer_address,
    customer_mobile,
    customer_note,
  }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .put(`counting/customers`, {
        customer_id,
        customer_name,
        customer_birthday,
        customer_image,
        customer_address,
        customer_mobile,
        customer_note,
      })
      .then(Response => Response)
      .catch(error => {
        return error.response
      })
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    return axios
      .delete(`counting/customers/customer_id/${id}`)
      .then(Response => Response)
      .catch(error => {
        return error.response
      })
  }
}

export default new CustomerApi()
