<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">الزبائن</h1>
      <v-row class="mt-5">
        <v-col md="4" cols="12">
          <div class="d-md-flex">
            <v-btn tile color="primary" class="ml-2 mb-4 mb-md-0 mb-sm-0" :block="isScreenXs"
              @click="addDialog.open = true">
              اضافة زبون<v-icon right> fa-plus </v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col md="4" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="table.headers" loading-text="جاري التحميل ... الرجاء الانتظار" :items="table.Data"
            :options.sync="tableOptions" :server-items-length="table.totalData" :loading="table.loading"
            class="elevation-1" :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }">
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.customer_image="{ item }">
              <v-img :src="content_url + item.customer_image" width="50" height="50" v-if="item.customer_image"
                @click="showImage(item.customer_image)" class="teacher_image_table" />
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" class="ml-2" v-bind="attrs" size="20" v-on="on" @click="editItem(item)">
                    fa-edit
                  </v-icon>
                </template>
                <span>تعديل</span>
              </v-tooltip>
              <v-tooltip bottom v-if="!item.is_salary_other_exist && isAllowedToDelete(account_type)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#FF5252" v-bind="attrs" size="20" v-on="on" @click="deleteItem(item)">
                    fa-trash
                  </v-icon>
                </template>
                <span>حذف</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- add dialog -->
    <v-dialog v-model="addDialog.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">اضافة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="addDialog.isFormValidAdd">
              <v-row>
                <!-- customer image -->
                <v-col cols="12">
                  <div class="d-flex justify-space-around align-center">
                    <v-btn text color="primary" @click="$refs.customer_image_change.click()">
                      اضافة صورة (اختياري)</v-btn>
                    <input v-show="false" id="img1" ref="customer_image_change" type="file"
                      @change="changeCustomerImage($event)" />
                    <div style="width: 80px; height: 80px; border: 1px solid red">
                      <img v-if="this.customer_image_url || this.addData.customer_image" width="80" height="80"
                        :src="showLogo()" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="addData.customer_name" label="الاسم" :rules="rules.required" outlined required
                    dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="addData.customer_mobile" :rules="rules.phone" label="رقم الهاتف" outlined
                    required dense>
                  </v-text-field>
                </v-col>
                <!-- customer_birthday -->
                <v-col col="12" md="6">
                  <v-menu v-model="menuDate" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="addData.customer_birthday" dense label="تاريخ الميلاد" outlined readonly
                        v-bind="attrs" v-on="on" clearable>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="addData.customer_birthday" @input="menuDate = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="addData.customer_address" label="العنوان" outlined required dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="3" row-height="15" v-model="addData.customer_note"
                    label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="addDialog.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="addDialog.loading" :disabled="!addDialog.isFormValidAdd" @click="add">
            اضافة
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Class dialog -->
    <!-- edit Class dialog -->
    <v-dialog v-model="dialogEdit.open" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">تعديل</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="dialogEdit.isFormValid">
              <v-row>
                <!-- customer image -->
                <v-col cols="12">
                  <div class="d-flex justify-space-around align-center">
                    <v-btn text color="primary" @click="$refs.customer_image_change_edit.click()">
                      اضافة صورة (اختياري)</v-btn>
                    <input v-show="false" id="img1" ref="customer_image_change_edit" type="file"
                      @change="changeCustomerImageEdit($event)" />
                    <div style="width: 80px; height: 80px; border: 1px solid red">
                      <img v-if="this.customer_image_url_edit || this.editedItem.customer_image" width="80" height="80"
                        :src="showLogoEdit()" />
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="editedItem.customer_name" label="الاسم" :rules="rules.required" outlined
                    required dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="editedItem.customer_mobile" :rules="rules.phone" label="رقم الهاتف" outlined
                    required dense>
                  </v-text-field>
                </v-col>
                <!-- customer_birthday -->
                <v-col col="12" md="6">
                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedItem.customer_birthday" dense label="تاريخ الميلاد" outlined readonly
                        v-bind="attrs" v-on="on" clearable>
                      </v-text-field>
                    </template>
                    <v-date-picker v-model="editedItem.customer_birthday" @input="menu = false"> </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field v-model="editedItem.customer_address" label="العنوان" outlined required dense>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea outlined rows="3" row-height="15" v-model="editedItem.customer_note"
                    label="الملاحظة"></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogEdit.open = false"> الغاء </v-btn>
          <v-btn color="primary" :loading="dialogEdit.loading" :disabled="!dialogEdit.isFormValid"
            @click="editItemConform">
            تعديل
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete dialog -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline justify-center"> هل انت متأكد من حذف هذا العنصر ؟ </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"> الغاء </v-btn>
          <v-btn color="primary white--text" :loading="deleteItemLoading" @click="deleteItemConfirm"> حذف </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End delete dialog -->
    <!--- Dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
    <v-dialog v-model="table.showImageDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">عرض الصورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row class="justify-center">
                <img :src="content_url + table.imageUrlForShow" alt width="300" height="200" />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="table.showImageDialog = false"> الغاء </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
  </div>
</template>

<script>
import customerApi from '@/api/customer'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'
import { isAllowedToDelete } from '@/constant/isAllowed'

export default {
  data() {
    return {
      menu: false,

      imgAndDivShowControll: {
        IsDiv1Showing: true,
        isImg1Showing: false,

        IsDiv1ShowingEdit: true,
        isImg1ShowingEdit: false,

        IsDiv2Showing: true,
        isImg2Showing: false,

        IsDiv3Showing: true,
        isImg3Showing: false,
      },

      customer_image_url: null,

      customer_image_url_edit: null,

      account_type: null,

      rate: 0,

      errors: {},

      menuDate: false,

      menuDateEdit: false,

      icons: {
        mdiCashMultiple,
        mdiCash,
      },

      rules: {
        required: [value => !!value || 'الحقل مطلوب'],
        phone: [v => !!v || 'رقم الهاتف مطلوب', v => /^07\d{9}$/.test(v) || 'الرقم يجب ان يبدأ "07" ويتكون من 11 رقم'],
      },

      addData: {
        customer_name: null,
        customer_birthday: null,
        customer_address: null,
        customer_note: null,
        customer_image: null,
        customer_mobile: null,
      },

      remainingPrice: null,
      remainingPriceEdit: null,

      allPrice: null,
      allPriceEdit: null,

      deleteItemLoading: false,

      content_url: null,

      dialogDelete: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      editedItem: {},

      dialogEdit: {
        open: false,
        isFormValid: false,
        loading: false,
      },

      xlsxData: {
        list: null,
        listLoading: true,
        downloadLoading: false,
        filename: 'الرصيد المخزني',
        autoWidth: true,
        bookType: 'xlsx',
      },

      allData: [],

      isScreenXs: false,

      deletedItem: {},

      tableOptions: {},

      addDialog: {
        open: false,
        isFormValidAdd: false,
        saveLoading: false,
        loading: false,
      },

      suppliersLoading: false,

      suppliersData: [],

      materialLoading: false,

      materialsData: [],

      content_url: null,

      selectedPersonalImg: null,

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDialog: false,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
            width: 1,
          },
          { text: 'الاسم', sortable: false, value: 'customer_name' },
          { text: 'تاريخ الميلاد', sortable: false, value: 'customer_birthday' },
          { text: 'الهاتف', sortable: false, value: 'customer_mobile' },
          { text: 'الصورة', sortable: false, value: 'customer_image' },
          { text: 'العنوان', sortable: false, value: 'customer_address' },
          { text: 'الملاحظات', sortable: false, value: 'customer_note' },
          { text: 'العمليات', value: 'actions', sortable: false },
        ],
      },
    }
  },

  created() {
    const account_type = this.$store.state.results.account_type
    this.account_type = account_type
  },

  watch: {
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
  },

  methods: {
    async getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = null
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await customerApi.get({
        search,
        page,
        limit: itemsPerPage,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
      }
    },

    showImage(image) {
      this.table.showImageDialog = true
      this.table.imageUrlForShow = image
    },

    changeCustomerImage(event) {
      this.imgAndDivShowControll.IsDiv1Showing = false
      this.imgAndDivShowControll.isImg1Showing = true

      const file = event.target.files[0]
      this.customer_image_url = null
      if (typeof file !== 'undefined' && file !== null) {
        const fileExt = file.name.split('.').pop()
        if (
          fileExt.toLowerCase() === 'jpg' ||
          fileExt.toLowerCase() === 'jpeg' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'gif'
        ) {
          this.customer_image_url = URL.createObjectURL(file)
          this.addData.customer_image = file
        } else {
          console.log('error_image_add')
        }
      } else {
        this.customer_image_url = null
      }
    },

    changeCustomerImageEdit(event) {
      this.imgAndDivShowControll.IsDiv1ShowingEdit = false
      this.imgAndDivShowControll.isImg1ShowingEdit = true

      const file = event.target.files[0]
      this.customer_image_url_edit = null
      if (typeof file !== 'undefined' && file !== null) {
        const fileExt = file.name.split('.').pop()
        if (
          fileExt.toLowerCase() === 'jpg' ||
          fileExt.toLowerCase() === 'jpeg' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'png' ||
          fileExt.toLowerCase() === 'gif'
        ) {
          this.customer_image_url_edit = URL.createObjectURL(file)
          this.editedItem.customer_image = file
        } else {
          console.log('error_image_add')
        }
      } else {
        this.customer_image_url_edit = null
      }
    },

    showLogo() {
      if (!this.customer_image_url) {
        return this.content_url + this.addData.customer_image
      } else {
        return this.customer_image_url
      }
    },

    showLogoEdit() {
      if (!this.customer_image_url_edit) {
        return this.content_url + this.editedItem.customer_image
      } else {
        return this.customer_image_url_edit
      }
    },

    editItem(item) {
      this.editedItem = { ...item }
      this.dialogEdit.open = true
    },

    async editItemConform() {
      this.dialogEdit.open = true
      this.dialogEdit.loading = true

      let customer_image = this.editedItem.customer_image
        ? await this.imageToBase64(this.editedItem.customer_image)
        : null

      const response = await customerApi.edit({
        customer_name: this.editedItem.customer_name,
        customer_birthday: this.editedItem.customer_birthday,
        customer_address: this.editedItem.customer_address,
        customer_note: this.editedItem.customer_note,
        customer_mobile: this.editedItem.customer_mobile,
        customer_image,
        customer_id: this.editedItem._id,
      })
      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.dialogEdit.open = false
        this.dialogEdit.loading = false
        this.getDataAxios()
        this.showDialogfunction(response.data.message, 'primary')
      }
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    imageToBase64(file) {
      if (file) {
        if (file.name) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
          })
        } else {
          return file
        }
      }
    },

    async add() {
      this.addDialog.loading = true

      let customer_image = this.addData.customer_image ? await this.imageToBase64(this.addData.customer_image) : null

      const response = await customerApi.add({
        customer_name: this.addData.customer_name,
        customer_birthday: this.addData.customer_birthday,
        customer_mobile: this.addData.customer_mobile,
        customer_image,
        customer_address: this.addData.customer_address,
        customer_note: this.addData.customer_note,
      })

      if (response.status === 401) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.addDialog.loading = false
        this.addDialog.open = false
        this.showDialogfunction(response.data.message, 'primary')
        this.getDataAxios()
      }
    },

    deleteItem(item) {
      this.deletedItem = { ...item }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      this.deleteItemLoading = true

      const response = await customerApi.remove(this.deletedItem._id)

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.deleteItemLoading = false
        this.showDialogfunction(response.data.message, '#FF5252')
      } else {
        this.deleteItemLoading = false
        this.dialogDelete = false
        this.getDataAxios()
        this.showDialogfunction(response.data.message, 'primary')
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = evt.key
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },

    numberWithComma,

    isAllowedToDelete,
  },
}
</script>

<style lang="scss" scoped>
.image_select_container {
  display: flex;
  justify-content: center;
  align-items: center;

  .image_select {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    width: 200px;
    border: 1px dashed #ccc;
    border-radius: 50px;
    margin-bottom: 10px;
  }
}
</style>
